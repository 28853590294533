import "./Socials.css";

export function Socials() {

    return(
        <div className="sm-icons">
            <a href="https://www.youtube.com/channel/UCyhGxkHtdVX3I6Vgh6x5Dpg" target="_blank"><div className="icons" id="youtube"></div></a>
            <a href="https://www.instagram.com/fastpace_music/" target="_blank"><div className="icons" id="instagram"></div></a>
            <a href="https://twitter.com/Fastpace13" target="_blank"><div className="icons" id="twitter"></div></a>
        </div>
    );

}