import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

let apiTokens = {
    clientToken: "AIzaSyDO4vW5EjzsdwLMu0TvcSvg5C3NyyTaf14"
}

const token = `https://youtube.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=10&playlistId=PL7jZZhrIt9YNdqk445G3tsk1W0aOwvkwj&key=${apiTokens.clientToken}`;

const youtubeVidThunk = createAsyncThunk('yt/videos', async () => {

    let artistResp = await axios.get(token, {
        headers: {
        }
    });
    console.log(artistResp.data);
    return artistResp.data;

});


export {youtubeVidThunk};
