import { createSlice } from "@reduxjs/toolkit";
import { youtubeVidThunk } from "../thunks/youtubeVidThunk";

const youtubeVidSlice = createSlice({
    name: 'yt-slice',
    initialState: {
        data: null,
        isLoading: false,
        error: null,
    },
    extraReducers(builder) {
        builder.addCase(youtubeVidThunk.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(youtubeVidThunk.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(youtubeVidThunk.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error;
        });
    }
});

export default youtubeVidSlice.reducer;