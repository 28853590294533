import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { artistAPIThunk, getArtistData } from '../../store/store';
import { youtubeVidThunk } from '../../store/store';
import { ReturnUp } from '../../components/returnUp/returnUp';
import "./artistPage.css";
import { Socials } from '../../components/Socials/Socials';


export function ArtistPage() {

    const dispatch = useDispatch();
    const artistData = useSelector((state) => state.artist.data);
    const youtubeData = useSelector((state) => state.youtubeVid.data);

    const videoLink = "https://www.youtube.com/watch?v=";

    useEffect(() => {
        dispatch(artistAPIThunk());
        dispatch(youtubeVidThunk());
    }, [dispatch]);

    console.log(11111, artistData);

    return(
        <div className="artist-page">
            <ul className="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
            </ul>
            {artistData && 
            <div className='about-me song-page'>
                {/* style={{content: `url(${artistData?.images[1].url})`}} */}
                <div className="artist-img" alt="artist" id='the-artist' onClick={() => {window.open(videoLink+youtubeData.item.snippet.resourceId.videoId)}}/>
                <div className='artist'>
                    <h1 className="title" id='name'>Fastpace</h1>
                    <h3 className='info' id='bio'>Fastpace started making music in 2018. He is a EDM producer who is steadily growing his fanbase. His inspirations are: Avicii, Armin Van Buuren and ILLENIUM.</h3>
                </div>
            </div>
            }
        </div>
        
    );
}