import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { topTracksThunk } from '../../store/store';
import { artistAPIThunk } from '../../store/store';
import { playlistThunk } from '../../store/store';
import { youtubeVidThunk } from '../../store/store';
import { Footer } from '../../components/footer/footer';
import { Header } from '../../components/header/header';
import { FcNext, FcPrevious } from "react-icons/fc";
import './main.css';
import { ArtistPage } from '../artistPage/artistPage';


export function MainPage() {


    const dispatch = useDispatch();
    const topTracksData = useSelector((state) => state.topTracks.data);
    const playlistData = useSelector((state) => state.playlist.data);
    const youtubeVidData = useSelector((state => state.youtubeVid.data));
    const artistData = useSelector((state) => state.artist.data);

    

    const [countIdx, setCountIdx] = useState(0);

    const leftClick = () => {
        setCountIdx(countIdx <= 0 ? topTracksData.tracks.length - 1 : countIdx - 1);
    }

    const rightClick = () => {
        setCountIdx(countIdx >= topTracksData.tracks.length - 1 ? 0 : countIdx + 1);
    }

    useEffect(() => {    
        
        // apiCall();
        dispatch(artistAPIThunk());
        dispatch(topTracksThunk());
        dispatch(playlistThunk());
        dispatch(youtubeVidThunk());
    }, [dispatch]);

    const nextIdx = countIdx >= topTracksData?.tracks.length - 1 ? 0 : countIdx + 1;

  return (
    <div>
        <Header/> 
        <div className='vid-page'>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/videoseries?si=AMnZWf-j0N-bQCHT&amp;controls=0&amp;list=PL7jZZhrIt9YNdqk445G3tsk1W0aOwvkwj" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen id='intro-page'></iframe>
        </div>
        <ArtistPage />
        
        {/* {topTracksData && (
        <div>
            <div className='arrows'>
                <FcPrevious className='left-arrow' size={25} onClick={leftClick}/>
                <FcNext className='right-arrow' size={25} onClick={rightClick}/>
            </div>
            <div className='top-tracks'>
                <Card data={topTracksData.tracks[countIdx]} />
                <Card data={topTracksData.tracks[nextIdx]} />
            </div>
        </div>
        
        )}

        
        <div className="playlists">
            <h1 className="title">Playlists</h1>
            {playlistData && (
            <div className="card-group">
            {playlistData.items.map((playlistInfo, index) => {
                return (
                    <MiniCard link={playlistInfo.external_urls.spotify } imgURL={playlistInfo.images[0].url} key={index} showVinyl={false}/>
                );
            })}
            
            </div>
            )}

        </div>

        <div className="listen-div">
            <h1 className="listen-btn">Listen Now!</h1>
            <div className='playlists'>
                <iframe width="400" height="315" src="https://www.youtube.com/embed/videoseries?si=WRd9Mdn4DolXicdE&amp;list=PL7jZZhrIt9YNdqk445G3tsk1W0aOwvkwj" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <iframe width="400" height="315" src="https://www.youtube.com/embed/videoseries?si=B3-pP8prjNVcre_D&amp;list=PL7jZZhrIt9YMaH2U8TRb-bmGNVGJse8yf" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <iframe width="400" height="315" src="https://www.youtube.com/embed/videoseries?si=IWlZoX_52WKUQJGk&amp;list=PL7jZZhrIt9YN_T_yCs5PUjBsDWVsGZkbD" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
        </div> */}
        {/* <Footer/> */}
    </div>
    );
}