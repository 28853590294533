import { Footer } from '../../components/footer/footer';
import { Header } from '../../components/header/header';
import { topTracksThunk } from '../../store/store';
import { youtubeVidThunk } from '../../store/store';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReturnUp } from '../../components/returnUp/returnUp';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import './songs.css';

export function SongsPage() {

    const dispatch = useDispatch();
    const topTracksData = useSelector((state) => state.topTracks.data);
    const youtubeVidData = useSelector((state => state.youtubeVid.data));

    const [isOrdered, setIsOrdered] = useState(0);
    const videoLink = "https://www.youtube.com/watch?v=";


    useEffect(() => {    
        
        // apiCall();
        dispatch(topTracksThunk());
        dispatch(youtubeVidThunk());

    }, [dispatch]);



    if (youtubeVidData) console.log(youtubeVidData.items[1].snippet.resourceId.videoId);

  return (

    <div className='songs'>
        <Header/>
        <BrowserView>
        <table className='cards' cellPadding="0" cellSpacing="0">
            <tbody>
                {youtubeVidData && 
                    youtubeVidData.items.map((item, index) => {
                        return (
                                <>
                                { (index % 2) === 0 &&
                                <tr key={index}>
                                    <td className="artwork" alt="artist" style={{content: `url(${item.snippet.thumbnails.maxres.url})`}} onClick={() => {window.open(videoLink+item.snippet.resourceId.videoId)}}></td>
                                    <td className='artist-info'>    
                                        <h1 className="title" id='name'>{item.snippet.title}</h1>
                                        <h3 className='info' id='bio'>{item.snippet.description}</h3>
                                    </td>
                                </tr>
                                }
                            
                                { (index % 2) === 1 &&
                                    <tr key={index}>
                                        <td className='artist-info-flipped'>    
                                            <h1 className="title" id='name'>{item.snippet.title}</h1>
                                            <h3 className='info' id='bio'>{item.snippet.description}</h3>
                                        </td>
                                        <td className="artwork" alt="artist" style={{content: `url(${item.snippet.thumbnails.maxres.url})`}} onClick={() => {window.open(videoLink+item.snippet.resourceId.videoId)}}/>
                                    </tr>
                                }
                                
                                </>
                            );
                    })
                                
                }
            </tbody>  
        </table>
        </BrowserView>

        
        {youtubeVidData &&  
            <MobileView>
                {youtubeVidData.items.map((item, index) => {
                    return (  
                        <div key={index}>
                            <div className="artwork" alt="artist" style={{content: `url(${item.snippet.thumbnails.maxres.url})`}} onClick={() => {window.open(videoLink+item.snippet.resourceId.videoId)}}></div>
                            <div className='artist-info'>    
                                <h1 className="title" id='name'>{item.snippet.title}</h1>
                                <h3 className='info' id='bio'>{item.snippet.description}</h3>
                            </div>
                        </div>
                    );
                })}
            </MobileView>
        }

        <ReturnUp />

            {/* <div className="all">
                {topTracksData && (
                    <h1>Top {topTracksData.tracks.length}:</h1>
                )}
                {topTracksData && (
                <div className='card-group'>
                    {topTracksData.tracks.map((tracksInfo, index) => {
                        return (
                            <MiniCard link={tracksInfo.external_urls.spotify} imgURL={tracksInfo.album.images[1].url} key={index} showVinyl={true}/>
                        );
                    })}
                </div>
                )}
                <h1>Playlists</h1>
                <div className='playlists'>
                    <iframe width="400" height="315" src="https://www.youtube.com/embed/videoseries?si=WRd9Mdn4DolXicdE&amp;list=PL7jZZhrIt9YNdqk445G3tsk1W0aOwvkwj" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    <iframe width="400" height="315" src="https://www.youtube.com/embed/videoseries?si=B3-pP8prjNVcre_D&amp;list=PL7jZZhrIt9YMaH2U8TRb-bmGNVGJse8yf" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    <iframe width="400" height="315" src="https://www.youtube.com/embed/videoseries?si=IWlZoX_52WKUQJGk&amp;list=PL7jZZhrIt9YN_T_yCs5PUjBsDWVsGZkbD" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </div> */}
        {/* <Footer/> */}
    </div>
    );
    
}