import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

let apiTokens = {
    clientID: "319b44ec31a54e7881436d51b8909651",
    clientToken: "c2946227d86642e9abd7dc0cdd96c65f"
}

let top_tracks = '/top-tracks?market=ES';


const artist = `https://api.spotify.com/v1/artists/3mJPZbwtANK3KqzgUSuJ8V`;
const user = 'https://api.spotify.com/v1/users/nwb1994whz37zefp5abdgvtce/playlists?limit=4';

const token = `https://accounts.spotify.com/api/token?grant_type=client_credentials&client_id=${apiTokens.clientID}&client_secret=${apiTokens.clientToken}`;

const artistAPIThunk = createAsyncThunk('api/artist', async () => {

    let response = await axios.post(token, null, {
        headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
    let accessToken = response.data.access_token;

    let artistResp = await axios.get(artist, {
        headers: {
        'Authorization': `Bearer ${accessToken}`
        }
    });
    console.log(666, artistResp.data);
    return artistResp.data;
});

const getArtistData = async () => {
    let response = await axios.post(token, null, {
        headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
    let accessToken = response.data.access_token;

    let artistResp = await axios.get(artist, {
        headers: {
        'Authorization': `Bearer ${accessToken}`
        }
    });
    console.log(777, artistResp.data);
    return artistResp.data;
}
export {artistAPIThunk, getArtistData};
