import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

let apiTokens = {
    clientID: "319b44ec31a54e7881436d51b8909651",
    clientToken: "c2946227d86642e9abd7dc0cdd96c65f"
}


const artist = `https://api.spotify.com/v1/artists/3mJPZbwtANK3KqzgUSuJ8V/top-tracks?market=ES`;

const token = `https://accounts.spotify.com/api/token?grant_type=client_credentials&client_id=${apiTokens.clientID}&client_secret=${apiTokens.clientToken}`;

const topTracksThunk = createAsyncThunk('top/tracks', async () => {

    let response = await axios.post(token, null, {
        headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
    let accessToken = response.data.access_token;

    let artistResp = await axios.get(artist, {
        headers: {
        'Authorization': `Bearer ${accessToken}`
        }
    });
    return artistResp.data;

});


export {topTracksThunk};
