
import { useEffect, useState } from 'react';
import { Socials } from '../Socials/Socials';
import styles from './header.module.css';

export function Header() {

  useEffect(() => {
      
  }, []);

  return (
    <header className={styles.header}>
      {/* <img src="/images/FP-Logo.png" alt="logo" className={styles.logo} width="60px" height="60px"/> */}
      <div className={styles.logo}>Fastpace</div>
      {/* <div className={styles.flexFiller}></div> */}
      {/* <Socials /> */}
      <div className={styles.center}><Socials /></div>
      <nav>
          <ul>
              <li><a style={{opacity: window.location.pathname === "/" ? 1 : 0.7}} href="/">Home</a></li>
              {/* <li><a href="/about">Contact</a></li> */}
              <li><a style={{opacity: window.location.pathname === "/demos" ? 1 : 0.7}} href="/demos">Songs</a></li>
              {/* <li id='support'><a href="https://www.patreon.com/fastpacemusic">Donate</a></li> */}
          </ul>
      </nav>
    </header>
  );
}
