import { createSlice } from "@reduxjs/toolkit";
import { playlistThunk } from "../thunks/playlistThunk";

const playlistSlice = createSlice({
    name: 'playlists',
    initialState: {
        data: null,
        isLoading: false,
        error: null,
    },
    extraReducers(builder) {
        builder.addCase(playlistThunk.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(playlistThunk.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(playlistThunk.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error;
        });
    }
});

export default playlistSlice.reducer;