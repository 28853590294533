import { configureStore } from '@reduxjs/toolkit';
import artistSlice from './slices/artistSlice';
import topTracksSlice from './slices/topTracksSlice';
import playlistSlice from './slices/playlistSlice';
import youtubeVidSlice from './slices/youtubeVidSlice';

export const store = configureStore({
  reducer: {
    artist: artistSlice,
    topTracks: topTracksSlice,
    playlist: playlistSlice,
    youtubeVid: youtubeVidSlice,
  },
});

export * from './thunks/artistAPIThunk';
export * from './thunks/topTracksThunk';
export * from './thunks/playlistThunk';
export * from './thunks/youtubeVidThunk';
