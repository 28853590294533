import React from 'react';
import { useDispatch } from 'react-redux';
import "./returnUp.css";

export function ReturnUp() {

    return(
        <div className='return-home' onClick={() => {window.scrollTo({ top: 0, behavior: 'smooth' });}}>
            <div className='arrow'>▲</div>
        </div>  
    );


}