import React from 'react';
import ReactDOM from 'react-dom/client';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { MainPage } from './pages/main/main';
import { SongsPage } from './pages/songs/songs';
import { ArtistPage } from './pages/artistPage/artistPage';

import {store} from './store/store';
import { Provider } from 'react-redux'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
  < BrowserRouter >
    <Routes>
        <Route index element={<MainPage />} />
        <Route path="/about" element={<ArtistPage />} />
        <Route path="/demos" element={<SongsPage/>} />
    </Routes>
  </ BrowserRouter >
  </Provider>
);

// const routs = (
//   < BrowserRouter >
//      <div>
//         <Route exact path="/" component={MainPage} />
//         <Route path="/about" component={AboutPage} />
//         <Route path="/demos" component={DemoPage} />
//      </div>
//   </ BrowserRouter >
// );
// ReactDOM.render(routs, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
