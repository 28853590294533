import { createSlice } from "@reduxjs/toolkit";
import { artistAPIThunk } from "../thunks/artistAPIThunk";

const artistSlice = createSlice({
    name: 'artist',
    initialState: {
        data: null,
        isLoading: false,
        error: null,
    },
    extraReducers(builder) {
        builder.addCase(artistAPIThunk.pending, (state, action) => {
            console.log(1, action);
            state.isLoading = true;
        });
        builder.addCase(artistAPIThunk.fulfilled, (state, action) => {
            console.log(2, action);
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(artistAPIThunk.rejected, (state, action) => {
            console.log(3, state);
            state.isLoading = false;
            state.error = action.error;
        });
    }
});

export default artistSlice.reducer;