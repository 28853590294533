import { createSlice } from "@reduxjs/toolkit";
import { topTracksThunk } from "../thunks/topTracksThunk";

const topTracksSlice = createSlice({
    name: 'topTracks',
    initialState: {
        data: null,
        isLoading: false,
        error: null,
    },
    extraReducers(builder) {
        builder.addCase(topTracksThunk.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(topTracksThunk.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(topTracksThunk.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error;
        });
    }
});

export default topTracksSlice.reducer;